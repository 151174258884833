import {API_DATA_IMPORT_URL, LS_TOKEN_KEY} from "../config";
import {
    FileUpload,
    FileUploadBeforeUploadEvent,
    FileUploadErrorEvent,
    FileUploadUploadEvent
} from "primereact/fileupload";
import React, {useRef, useState} from "react";
import useUserNotification from "../hooks/useUserNotification";
import {Button} from "primereact/button";
import {errorMessage} from "../helpers/axiosError";
import {AxiosResponse} from "axios";
import {confirmDialog} from "primereact/confirmdialog";
import {UserDataRow} from "../interfaces/UserDataRow";
import {DataTable} from "primereact/datatable";
import {Column, ColumnBodyOptions} from "primereact/column";
import {NameTitlePair, toPropertyTitle} from "../components/exchange/UserPropertyNamesConverter";
import {ProcessingResult} from "../interfaces/ProcessingResult";
import {Messages, MessagesMessage} from "primereact/messages";
import {useMountEffect} from "primereact/hooks";
import {Dialog} from "primereact/dialog";
import UserDataImportSettingsPanel from "./UserDataImportSettingsPanel";
import CancelButton from "../components/common/buttons/CancelButton";
import SaveButton from "../components/common/buttons/SaveButton";
import {userDataImportService} from "../service/exchange/excel/UserDataImportService";
import {useAuth} from "../security/AuthProvider";
import {initialUserDataImportSettings} from "../interfaces/UserDataImportSettings";

interface ColumnMeta {
    field: string;
    header: string;
}

const EMPTY = "<пусто>";

const initialResult: ProcessingResult<UserDataRow[]> = {
    data: [],
    errors: [],
    warnings: []
};

export default function UserDataImportPage() {

    const [busy, setBusy] = useState<boolean>(false);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [compared, setCompared] = useState<boolean>(false);
    const [result, setResult] = useState<ProcessingResult<UserDataRow[]>>(initialResult);
    const [columns, setColumns] = useState<ColumnMeta[]>([]);
    const messages = useRef<Messages>(null);

    const {showError, showSuccess} = useUserNotification();
    const {user} = useAuth();

    const settings = user.settings.userDataImportSettings ?
        user.settings.userDataImportSettings : initialUserDataImportSettings;
    const editedSettings = Object.assign(settings);


    const init = () => {
        setResult(initialResult);
        setColumns([]);
        setUploaded(false);
        setCompared(false);
    };

    const reload = () => {
        setBusy(true);

        userDataImportService.result()
            .then((r: ProcessingResult<UserDataRow[]>) => {
                loadResult(r);

            })
            .catch(reason => {
                init();
                showError({
                    summary: "Загрузка результатов сравнения",
                    detail: "Ошибка загрузки результатов сравнения." + errorMessage(reason)
                });
            })
            .finally(() => setBusy(false));

        /*        setBusy(true);

                userDataImportService.result()
                    .then((r: ProcessingResult<UserDataRow[]>) => {
                        const data = r.data;
                        if (data && data.length > 0) {
                            let first: UserDataRow = data[0];
                            let propertyNames = Object.keys(first.properties);
                            let columnMetas: ColumnMeta[] = propertyNames
                                .filter((propertyName: string) => propertyName !== "id")
                                .map((propertyName: string) => {
                                    const propertyTitle = toPropertyTitle(propertyName)
                                    return {
                                        field: propertyName,
                                        header: propertyTitle ? propertyTitle : propertyName
                                    }
                                });

                            setColumns(columnMetas);
                            setUploaded(true);
                            setCompared(true);
                        }
                        setDataRows(data || []);

                    })
                    .catch(reason => {
                        init();
                        showError({
                            summary: "Загрузка результатов сравнения",
                            detail: "Ошибка загрузки результатов сравнения." + errorMessage(reason)
                        });
                    })
                    .finally(() => setBusy(false));*/
    }

    useMountEffect(() => reload());

    /*useUpdateEffect(() => {
        reload();
    }, [tableState]);*/

    const onBeforeUpload = (e: FileUploadBeforeUploadEvent) => {
        setBusy(true);
        const token = localStorage.getItem(LS_TOKEN_KEY);
        e.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    };

    const loadResult = (response: ProcessingResult<UserDataRow[]>) => {
        setUploaded(true);
        setCompared(false);

        if (!response) {
            init();
            return;
        }

        //setResult(initialResult);
        const msgs: MessagesMessage[] = [];

        if (response.warnings) {
            response.warnings.forEach(w => msgs.push({
                sticky: true,
                severity: "warn",
                summary: null,
                detail: w
            }));
        }

        if (response.errors) {
            response.errors?.forEach(e => msgs.push({
                sticky: true,
                severity: "error",
                summary: null,
                detail: e
            }));
        }

        messages.current?.clear();
        messages.current?.show(msgs);

        if (response.data && response.data.length > 0) {
            let first: UserDataRow = response.data[0];
            let propertyNames = Object.keys(first.properties);
            let columnMetas: ColumnMeta[] = propertyNames
                .filter((propertyName: string) => propertyName !== "id")
                .map((propertyName: string) => {
                    const propertyTitle = toPropertyTitle(propertyName)
                    return {
                        field: propertyName,
                        header: propertyTitle ? propertyTitle : propertyName
                    }
                });

            setColumns(columnMetas);
        }

        setResult(response);
    }

    const onUpload = (e: FileUploadUploadEvent) => {
        const r: ProcessingResult<UserDataRow[]> = JSON.parse(e.xhr.response);
        loadResult(r);
        setBusy(false);

        /*showSuccess({
            summary: "Загрузка файла",
            detail: "Файл обновления базы данных пользователей успешно загружен."
        });*/
        //reload();
    };

    const onUploadError = (event: FileUploadErrorEvent) => {
        setBusy(false);
        init();
        // console.log(JSON.stringify(event));
        showError({
            summary: "Загрузка файла",
            detail: "Ошибка загрузки файла данных. " + event.xhr.statusText
        });
    };

    const comparison = () => {
        setBusy(true);

        userDataImportService.comparison()
            .then((response: AxiosResponse<ProcessingResult<UserDataRow[]>>) => {
                loadResult(response.data);
                setCompared(true);
                /*showSuccess({
                    summary: "Сравнение",
                    detail: "Выполнено сравнение данных загруженного файла и базы данных."
                });*/
            })
            .catch(reason => {
                setCompared(false);
                showError({
                    summary: "Обработка файла",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => setBusy(false));
    };

    const clear = () => {
        setBusy(true);

        userDataImportService.clear()
            .then(() => {
                init();
                reload();

            })
            .catch(reason => {
                showError({
                    summary: "Очистка результатов сравнения",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => setBusy(false));
    };

    const saveChanges = () => {
        setBusy(true);

        userDataImportService.save()
            .then(() => {
                setUploaded(false);
                setCompared(false);
                showSuccess({
                    summary: "Сохранение изменений",
                    detail: "Все изменения приняты и сохранены в базу данных."
                });
                reload();

            })
            .catch(reason => {
                showError({
                    summary: "Сохранение изменений",
                    detail: errorMessage(reason)
                });
            })
            .finally(() => setBusy(false));
    };

    const saveChangesConfirmation = () => {
        confirmDialog({
            message: "Вы действительно хотите принять все выявленные изменения и сохранить их в базе данных? Результат операции необратим. Если не уверены, обратитесь к системному администратору для создания резервной копии данных.",
            header: "Сохранение изменений",
            icon: "pi pi-info-circle",
            acceptClassName: 'p-button-danger',
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                saveChanges();
            }
        });
    };

    const hasErrors = () => {
        return result && result.errors.length > 0;
    }

    /* Шапка и подвал таблицы */

    const tableHeader = (
        <div className="flex flex-row gap-1">
            <Button icon="pi pi-fw pi-refresh"
                    tooltip="Обновить" tooltipOptions={{position: 'top'}}
                    onClick={reload}
                    className="p-button-outlined"
            />

            <FileUpload id="file"
                        mode="basic"
                        auto
                        disabled={busy}
                        name="file"
                        onBeforeSend={onBeforeUpload}
                        url={`${API_DATA_IMPORT_URL}/upload`}
                        maxFileSize={134217728}
                        onUpload={onUpload}
                        onError={onUploadError}
            />
            <Button type={"button"}
                    onClick={() => {
                        setShowSettings(true);
                    }}
                    icon={"pi pi-fw pi-cog"}
                    disabled={busy}
                    tooltipOptions={{position: 'top'}}
                    tooltip="Настройка параметров процедуры импорта данных"
            />
            <Button label="Сравнить"
                    type="button"
                    onClick={comparison}
                    disabled={busy || !uploaded || compared || hasErrors()}
                    tooltipOptions={{position: 'top'}}
                    tooltip="Сопоставить данные загруженного файла и базы данных, отобразить выявленные изменения."
            />
            <Button label="Сохранить"
                    type="button"
                    disabled={busy || !compared || hasErrors()}
                    onClick={saveChangesConfirmation}
                    tooltipOptions={{position: 'top'}}
                    severity="warning"
                    tooltip="Принять все выявленные изменения и сохранить в базу данных."
            />
            <Button icon="pi pi-fw pi-trash"
                    type="button"
                    disabled={busy}
                    onClick={clear}
                    severity="warning"
                    tooltipOptions={{position: 'top'}}
                    tooltip="Очистить результаты сравнения"
            />
        </div>
    );

    const tableFooter = `Всего ${result.data?.length || 0}`;

    const propertyBodyTemplate = (row: UserDataRow, options: ColumnBodyOptions) => {
        let cellPropertyName = options.field;
        let cellPropertyValue = row.properties[cellPropertyName];
        let cellChanges = row.changes
            .filter(changes => changes.propertyName === cellPropertyName);
        let isCellModified = cellChanges.length > 0;

        if (row.status === 'APPEARED') {
            return (
                <span className="created">{cellPropertyValue}</span>
            );
        } else if (isCellModified) {
            return (
                <span className="modified">
                    {cellChanges.map((highlight, index) => {
                        return (
                            <span key={index}>
                                {cellPropertyValue ? cellPropertyValue : EMPTY}
                                {row.status === 'MODIFIED' &&
                                    <span className="old">{highlight.oldValue ? highlight.oldValue : EMPTY}</span>}
                            </span>
                        );
                    })}
                </span>
            );
        } else {
            return cellPropertyValue;
        }
    };

    const statusNameTitlePairs: NameTitlePair[] = [
        {name: "HAS_NOT_CHANGED", title: "Нет изменений"},
        {name: "MODIFIED", title: "Изменения"},
        {name: "ABSENT", title: "Отсутствует"},
        {name: "APPEARED", title: "Добавлена"}
    ];

    const statusBodyTemplate = (row: UserDataRow) => {
        let pair = statusNameTitlePairs.find(p => p.name === row.status);
        return pair ? pair.title : row.status;
    };

    const noticesBodyTemplate = (row: UserDataRow) => {
        return row.errors.length > 0 || row.warnings.length > 0 ?
            <>
                <div className="text-yellow-700">
                    {row.warnings.map((w, index) => <div key={index} className="mb-2">{w}</div>)}
                </div>
                <div className="text-red-700">
                    {row.errors.map((n, index) => <div key={index} className="mb-2">{n}</div>)}
                </div>
            </>
            : undefined;
    };

    const settingsDialogFooter = (
        <div>
            <SaveButton disabled={busy}
                        onClick={() => {
                            setShowSettings(false);
                            user.settings = Object.assign(editedSettings);
                        }}/>
            <CancelButton disabled={busy}
                          onClick={() => {
                              setShowSettings(false);
                          }}/>
        </div>
    );

    return (
        <>
            <div className="container-fluid">
                <Messages ref={messages}/>
                <DataTable value={result.data ? result.data : undefined} dataKey="id"
                           loading={busy}
                           header={tableHeader} footer={tableFooter}
                           size="small"
                           emptyMessage="Файл не загружен, или отсутствуют изменения.">

                    <Column field="notices"
                            header="Замечания"
                            body={noticesBodyTemplate}
                            style={{minWidth: '13rem'}}
                    />
                    {/*<Column field="id" header="УН" sortable/>*/}
                    {
                        columns.map((col) => {
                            return <Column key={col.field} field={col.field}
                                           header={col.header}
                                           body={propertyBodyTemplate}/>
                        })
                    }
                    <Column field="status" header="Статус" body={statusBodyTemplate}/>
                </DataTable>
            </div>

            <Dialog header={"Настройки параметров импорта пользовательских данных"}
                    visible={showSettings}
                    onHide={() => setShowSettings(false)}
                    footer={settingsDialogFooter}
                    className="app-dialog">
                <UserDataImportSettingsPanel settings={editedSettings}/>
            </Dialog>
        </>
    );
}
import AbstractEntity from "./AbstractEntity";
import {initialUserDataImportSettings, UserDataImportSettings} from "./UserDataImportSettings";

export interface UserSettings extends AbstractEntity {
    userDataImportSettings: UserDataImportSettings | null | undefined;
}

export const initialUserSettings: UserSettings = {
    id: null,
    userDataImportSettings: initialUserDataImportSettings
};
import {Button} from "primereact/button";
import {useState} from "react";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import {confirmDialog} from "primereact/confirmdialog";
import useUserNotification from "../hooks/useUserNotification";
import {errorMessage} from "../helpers/axiosError";
import User from "../interfaces/User.interface";
import {Calendar} from "primereact/calendar";
import {BlockUI} from "primereact/blockui";
import {Password} from "primereact/password";
import {RegistrationService} from "../service/RegistrationService";
import {AxiosError} from "axios";
import {ApiErrorResponse} from "../interfaces/response.interface";
import RegistrationNdaLink from "./RegistrationNdaLink";
import JwtAuthenticationResponse from "../interfaces/auth/JwtAuthenticationResponse";
import {useNavigate} from "react-router-dom";
import {authService} from "../service/AuthService";


type registrationSteps =
    "contactsVerification" |
    "userProfileConfirmation";

type contactsVerificationSteps =
    "input" |
    "code_request" |
    "code_verification" |
    "code_verified" |
    "code_wrong";

interface RegistrationProps {
    onAccept(): void;

    onReject(): void;
}

export default function Registration(props: RegistrationProps) {
    const [busy, setBusy] =
        useState<boolean>(false);

    //const [phone, setPhone] = useState<string | undefined>(undefined);
    const [email, setEmail] =
        useState<string>('');

    const [verificationHashCode, setVerificationHashCode] =
        useState<string | undefined>(undefined);

    const [userRegistrationHashCode, setUserRegistrationHashCode] =
        useState<string | undefined>(undefined);

    const [insurancePolicyNumber, setInsurancePolicyNumber] =
        useState<string>('');

    //const [phoneCode, setPhoneCode] = useState<string>("");
    const [emailCode, setEmailCode] = useState<string>("");

    const [isUserAgreed, setIsUserAgreed] = useState<boolean>(false);

    //const isPhoneFormatCorrect = !!(phone && cleanPhone(phone).length === 10);
    const isEmailFormatCorrect = (/.+@.+\.[A-Za-z]+$/.test(email));
    const isInsurancePoliceNumberCorrect = !!insurancePolicyNumber && insurancePolicyNumber.trim().length >= 5;

    const [registrationStep, setRegistrationStep] =
        useState<registrationSteps>("contactsVerification");

    const [emailVerificationStep, setEmailVerificationStep] =
        useState<contactsVerificationSteps>("input");

    const [user, setUser] =
        useState<User | undefined>(undefined);

    const [password, setPassword] =
        useState<string>('');

    const {showError, showWarn, showInfo, showSuccess} = useUserNotification();
    const navigate = useNavigate();

    // const registrationService = new RegistrationService();
    const registrationService = new RegistrationService();


    /* Phone */

    /*const phoneValidationStart = () => {
        confirmDialog({
            message: "На указанный номер телефона будет отправлен код подтверждения, продолжить?",
            header: "Проверка телефона",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {

            }
        })
    }*/


    /* Email */

    const emailVerificationStart = () => {
        confirmDialog({
            message: "На указанный адрес электронной почты будет отправлен код и ссылка подтверждения, продолжить?",
            header: "Проверка адреса электронной почты",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            className: "app-confirmation-dialog",
            accept() {
                setBusy(true);

                registrationService.emailVerificationStart(email)
                    .then((response) => {
                        setVerificationHashCode(response);
                        setEmailVerificationStep("code_verification");
                        showInfo({
                            summary: "Регистрация электронной почты",
                            detail: "Проверочный код отправлен."
                        });
                    })
                    .catch(reason => {
                        showError({
                            summary: "Ошибка отправки проверочного кода электронной почты",
                            detail: errorMessage(reason),
                        });
                    })
                    .finally(() => {
                        setBusy(false);
                    });
            }
        })
    }

    const emailCodeVerification = () => {
        if (verificationHashCode && emailCode) {
            setBusy(true);

            registrationService.emailVerificationCodeCheck(
                verificationHashCode, emailCode)
                .then(response => {
                    setUserRegistrationHashCode(response);
                    setEmailVerificationStep("code_verified");
                    showInfo({
                        summary: "Регистрация электронной почты",
                        detail: `Электронная почта ${email} успешно подтверждена.`
                    });
                })
                .catch((reason: AxiosError<ApiErrorResponse>) => {
                    setEmailVerificationStep("code_wrong");
                    showWarn({
                        summary: "Регистрация электронной почты",
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                });
        }
    }

    const requestUser = () => {
        if (userRegistrationHashCode && insurancePolicyNumber) {
            setBusy(true);

            registrationService.requestUser(
                userRegistrationHashCode, insurancePolicyNumber)
                .then(user => {
                    setUser(user);
                    setRegistrationStep("userProfileConfirmation");

                })
                .catch((reason: AxiosError<ApiErrorResponse>) => {
                    showError({
                        summary: "Регистрация",
                        detail: errorMessage(reason)
                    });
                })
                .finally(() => {
                    setBusy(false);
                });
        }
    }

    const acceptUserRegistration = () => {
        if (verificationHashCode && userRegistrationHashCode && password) {
            registrationService.acceptUserRegistration(
                verificationHashCode, userRegistrationHashCode, password)
                .then((response: JwtAuthenticationResponse) => {
                    showSuccess({
                        summary: "Регистрация",
                        detail: "Поздравляем! Вы успешно зарегистрированы."
                    });
                    props.onAccept();

                    // Вход вновь зарегистрированного пользователя в приложение
                    authService.jwtProcess(response.accessToken);
                    authService.jwtProcess(response.accessToken)?.then((user: User) => navigate("/"));
                    // window.location.href = "/";

                }).catch((reason: AxiosError<ApiErrorResponse>) => {
                showError({
                    summary: "Регистрация",
                    detail: errorMessage(reason)
                });
            });
        }
    }

    const rejectUserRegistration = () => {
        if (verificationHashCode && userRegistrationHashCode) {
            registrationService.rejectUserRegistration(
                verificationHashCode, userRegistrationHashCode)
                .then(() => props.onReject())
                .catch((reason: AxiosError<ApiErrorResponse>) => console.error(reason));
        }
    }


    const emailRender = (
        <div className="flex flex-row gap-2 justify-content-evenly align-items-center mt-2">
            {(emailVerificationStep === "input" ||
                    emailVerificationStep === "code_verified" ||
                    emailVerificationStep === "code_wrong") &&
                <span className="p-float-label flex-1">
                    <InputText id="email"
                               value={email}
                               required={true}
                               onChange={(e) => setEmail(e.target.value)}
                               disabled={
                                   emailVerificationStep === "code_verified" ||
                                   emailVerificationStep === "code_wrong"
                               }
                               className="w-full"
                    />
                    <label htmlFor="email">Email *</label>
                </span>
            }
            {emailVerificationStep === "input" &&
                <Button label="Проверить"
                        visible={isEmailFormatCorrect}
                        onClick={emailVerificationStart}
                />
            }
            {emailVerificationStep === "code_verified" &&
                <i className="pi pi-verified" style={{fontSize: '2rem', color: "green"}}></i>
            }
            {emailVerificationStep === "code_wrong" &&
                <i className="pi pi-verified" style={{fontSize: '2rem', color: "red"}}></i>
            }
            {emailVerificationStep === "code_verification" &&
                <>
                    <span className="p-float-label flex-1">
                        <InputText id="emailCode"
                                   value={emailCode}
                                   required={true}
                                   onChange={e => setEmailCode(e.target.value)}
                                   className="w-full"
                        />
                        <label htmlFor="emailCode">Проверочный код *</label>
                    </span>
                    <Button label="Подтвердить"
                            onClick={emailCodeVerification}/>
                </>
            }
        </div>
    );

    const insurancePolicyNumberRender = (
        <div className="flex flex-row gap-2 justify-content-evenly align-items-center">
                <span className="p-float-label flex-1">
                    <InputText id="policeNumber"
                               required={true}
                               value={insurancePolicyNumber}
                               onChange={(e) =>
                                   setInsurancePolicyNumber(e.target.value)}
                               className="w-full"/>
                    <label htmlFor="policeNumber">Номер страхового полиса *</label>
                </span>
        </div>
    );

    const userAgreementRender = (
        <div className="flex flex-column gap-0 my-3">
            <div className="flex flex-row gap-2">
                <Checkbox id="policyConfirmed"
                          checked={isUserAgreed}
                          value={!isUserAgreed}
                          onChange={(e) =>
                              setIsUserAgreed(e.checked ? e.checked : false)}/>
                <label htmlFor="policyConfirmed">Нажимая "Продолжить", Вы даёте <RegistrationNdaLink/>.</label>
            </div>
        </div>
    );

    const userRender = (
        <div className={"flex flex-column gap-4"}>
            <p>Если указанные ниже данные имеют непосредственное к Вам отношение, подтвердите завершение
                регистрации.</p>

            <span className="p-float-label flex-1">
                <InputText id="secondName"
                           value={user?.profile.secondName}
                           disabled={true}
                           className="w-full"/>
                <label htmlFor="secondName">Фамилия</label>
            </span>
            <span className="p-float-label flex-1">
                <InputText id="firstName"
                           value={user?.profile.firstName}
                           disabled={true}
                           className="w-full"/>
                <label htmlFor="firstName">Имя</label>
            </span>
            <span className="p-float-label flex-1">
                <InputText id="thirdName"
                           value={user?.profile.thirdName}
                           disabled={true}
                           className="w-full"/>
                <label htmlFor="thirdName">Отчество</label>
            </span>
            <span className="p-float-label flex-1">
                <Calendar id="dateOfBirth"
                          value={user?.profile.dateOfBirth ?
                              new Date(user?.profile.dateOfBirth) : new Date()}
                          dateFormat="dd.mm.yy"
                          disabled={true}
                          visible={!!user?.profile.dateOfBirth}
                          className="w-full"/>
                <label htmlFor="dateOfBirth">Дата рождения</label>
            </span>
            <span className="p-float-label flex-1">
                <InputText id="insurancePolicyNumber"
                           value={user?.profile.insurancePolicyNumber}
                           disabled={true}
                           className="w-full"/>
                <label htmlFor="insurancePolicyNumber">Номер страхового полиса</label>
            </span>
            <div className="notes mb-3">
                Пожалуйста, введите пароль, который будет использоваться для доступа в личный кабинет.
            </div>
            <span className="p-float-label flex-1">

                <Password id="passwod"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          toggleMask
                          min={8}
                          promptLabel="Введите пароль"
                          weakLabel="Ненадёжный пароль"
                          mediumLabel="Средний уровень"
                          strongLabel="Надёжный пароль"
                          className="w-full"/>
                <label htmlFor="passwod">Пароль</label>
            </span>
            <div className="flex flex-row gap-2 my-4 justify-content-end">
                <Button type="button"
                        label="Подтвердить"
                        disabled={!password || password.length === 0}
                        onClick={acceptUserRegistration}/>
                <Button type="button"
                        label="Отклонить"
                        className="p-button-text"
                        onClick={rejectUserRegistration}/>
            </div>
        </div>
    );


    return (
        <div className="flex flex-column gap-3 pt-4">
            <BlockUI blocked={busy}>
                {registrationStep === "contactsVerification" &&
                    <div className={"flex flex-column gap-4"}>
                        {emailRender}
                        {insurancePolicyNumberRender}
                        {userAgreementRender}
                        <div className="flex flex-column gap-3 m-0">
                            <Button type="submit"
                                    label="Продолжить"
                                    onClick={requestUser}
                                    disabled={!isUserAgreed ||
                                        emailVerificationStep !== "code_verified" ||
                                        !isInsurancePoliceNumberCorrect}
                            />
                        </div>
                    </div>
                }
                {registrationStep === "userProfileConfirmation" && user &&
                    <>
                        {userRender}
                    </>
                }
            </BlockUI>
        </div>
    );
}
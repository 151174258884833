import TemporalUpdatableEntity from "./TemporalUpdatableEntity";
import UserProfile, {initialUserProfile} from "./UserProfile.interface";
import {IMobilePhone} from "./IMobilePhone";
import {IPhone} from "./IPhone";
import {UserStatus} from "./enum/UserStatus";
import {ContactStatus} from "./enum/ContactStatus";
import {ROLE_REGISTERED_USER} from "../config";
import {initialUserSettings, UserSettings} from "./UserSettings";


export default interface User extends TemporalUpdatableEntity {
    username: string | undefined;
    password: string | undefined;
    status: UserStatus;
    roles: number[];
    mobilePhone: IMobilePhone | undefined;
    phone: IPhone | undefined;
    email?: string | undefined;
    emailStatus?: ContactStatus | undefined;
    lastLogin?: string | undefined;
    notes?: string | undefined;
    profile: UserProfile;
    settings: UserSettings;
}

export const initialUser: User = {
    id: null,
    username: undefined,
    password: undefined,
    status: UserStatus.VALIDATION,
    roles: [ROLE_REGISTERED_USER.code],
    mobilePhone: undefined,
    phone: undefined,
    email: undefined,
    emailStatus: undefined,
    notes: '',
    profile: initialUserProfile,
    settings: initialUserSettings
};
import {API_DATA_IMPORT_URL} from "../../../config";
import axios, {AxiosResponse} from "axios";
import {UserDataRow} from "../../../interfaces/UserDataRow";
import {ProcessingResult} from "../../../interfaces/ProcessingResult";
import {FieldMetadata} from "../../../interfaces/FieldMetadata";

export class UserDataImportService {
    url: string = API_DATA_IMPORT_URL;

    async comparison(): Promise<AxiosResponse<ProcessingResult<UserDataRow[]>>> {
        const request: string = this.url + "/comparison";
        return await axios.get(request);
    }

    async result(): Promise<ProcessingResult<UserDataRow[]>> {
        const request: string = this.url + "/result";
        const response: AxiosResponse<ProcessingResult<UserDataRow[]>> = await axios.get(request);
        return response.data;
    }

    async save(): Promise<AxiosResponse<any>> {
        const request: string = this.url + "/result";
        return await axios.patch(request);
    }

    async clear(): Promise<AxiosResponse<any>> {
        const request: string = this.url + "/result";
        return await axios.delete(request);
    }

    async fieldsMetadata(): Promise<AxiosResponse<FieldMetadata[]>> {
        const request: string = this.url + "/fields";
        return await axios.get<FieldMetadata[]>(request);
    }
}

export const userDataImportService: UserDataImportService = new UserDataImportService();
import UserDataImportFieldSettings from "./UserDataImportFieldSettings";

export interface UserDataImportSettings {

    skipEmptyValue: boolean | undefined;
    items: UserDataImportFieldSettings[] | undefined;
}

export const initialUserDataImportSettings: UserDataImportSettings = {
    skipEmptyValue: true,
    items: [
        {field:"profile.insurancePolicyNumber", enabled: true},
        {field:"profile.secondName", enabled: true},
        {field:"profile.firstName", enabled: true},
        {field:"profile.thirdName", enabled: true},
        {field:"profile.dateOfBirth", enabled: true},
    ]
}

import UserProfile from "./interfaces/UserProfile.interface";
import User from "./interfaces/User.interface";
import {UserStatus} from "./interfaces/enum/UserStatus";
import {ContactStatus} from "./interfaces/enum/ContactStatus";
import {initialUserSettings} from "./interfaces/UserSettings";

export const LS_KEY = "nsg-pa";
export const LS_USER_KEY = LS_KEY + "-user";
export const LS_USER_PROFILE_KEY = LS_KEY + "-profile";
export const LS_TOKEN_KEY = LS_KEY + "-token";

export const MAX_INT = 2147483647;

export const API_URL = process.env.REACT_APP_API_URL;
export const API_AUTH_URL = API_URL + "auth";
export const API_USERS_URL = API_URL + "users";
export const API_PROFILES_URL = API_URL + "profiles";
export const API_THERAPISTS_URL = API_URL + "therapists";
export const API_ROLES_URL = API_URL + "roles";
export const API_MEDIA_ASSETS_URL = API_URL + "mediaAssets";
export const API_MEDIA_ASSET_TYPE_URL = API_URL + "mediaAssetTypes";
export const API_DOCUMENT_TEMPLATES_URL = API_URL + "templates";
export const API_ORGANIZATIONS_URL = API_URL + "organizations";
export const API_TAXONOMY_URL = API_URL + "taxonomy";
export const API_SETTINGS_URL = API_URL + "settings";
export const API_DASHBOARD_URL = API_URL + "dashboard";
export const API_INSURANCE_PROGRAM_URL = API_URL + "insurancePrograms";
export const API_SERVICE_PROGRAM_URL = API_URL + "servicePrograms";
export const API_DISPATCHER_CONSOLE_URL = API_URL + "dispatcherConsoles";
export const API_DATA_EXPORT_URL = API_URL + "export";
export const API_DATA_IMPORT_URL = API_URL + "import";
export const API_LOG_URL = API_URL + "logs";
export const API_TICKET_URL = API_URL + "tickets";
export const API_TICKET_MESSAGE_URL = API_URL + "ticketMessages";
export const API_INSURERS_URL = API_URL + "insurers";
export const API_NOTIFICATION_LISTS_URL = API_URL + "notification/lists";
export const API_NOTIFICATION_RECIPIENTS_URL = API_URL + "notification/recipients";
export const API_MESSAGE_TEMPLATE_URL = API_URL + "messageTemplates";
export const API_MAIL_URL = API_URL + "mail";
export const API_MAINTENANCE_URL = API_URL + "maintenance";


/* Роли пользователей */

export type RoleType = {
    name: string;
    code: number;
    title: string;
}

export const ROLE_ANONYMOUS: RoleType = {code: 1, name: "ROLE_ANONYMOUS", title: "Анонимный"};
export const ROLE_REGISTERED_USER: RoleType = {code: 2, name: "ROLE_REGISTERED_USER", title: "Зарегистрированный"};
export const ROLE_ADMINISTRATOR: RoleType = {code: 3, name: "ROLE_ADMINISTRATOR", title: "Администратор"};
export const ROLE_MANAGER: RoleType = {code: 4, name: "ROLE_MANAGER", title: "Менеджер"};
export const ROLE_EDITOR: RoleType = {code: 5, name: "ROLE_EDITOR", title: "Редактор"};
export const ROLE_USER_MANAGEMENT: RoleType = {
    code: 6,
    name: "ROLE_USER_MANAGEMENT",
    title: "Управление пользователями"
};
export const ROLE_INSURED: RoleType = {code: 7, name: "ROLE_INSURED", title: "Застрахованный"};
export const ROLE_INSURER: RoleType = {code: 8, name: "ROLE_INSURER", title: "Страхователь"};
export const ROLE_THERAPIST: RoleType = {code: 9, name: "ROLE_THERAPIST", title: "Врач-куратор"};
export const ROLE_SUPPORT: RoleType = {code: 10, name: "ROLE_SUPPORT", title: "Техподдержка"};

export const roleTypes: RoleType[] = [
    ROLE_ANONYMOUS, ROLE_REGISTERED_USER, ROLE_ADMINISTRATOR,
    ROLE_MANAGER, ROLE_EDITOR, ROLE_USER_MANAGEMENT,
    ROLE_INSURED, ROLE_INSURER, ROLE_THERAPIST
];

export function findRoleType(id: number): RoleType | undefined {
    return roleTypes.find(roleType => roleType.code === id);
}


export const anonymousUserProfile: UserProfile = {
    id: null,
    firstName: "Аноним",
    secondName: ""
}

export const anonymousUser: User = {
    id: null,
    username: "anonymous",
    password: "",
    email: "",
    mobilePhone: {country: '', number: '', status: ContactStatus.BLOCKED},
    phone: {country: '', number: '', status: ContactStatus.BLOCKED},
    status: UserStatus.ACTIVE,
    roles: [ROLE_ANONYMOUS.code],
    profile: anonymousUserProfile,
    settings: initialUserSettings
};


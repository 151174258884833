import React, {useState} from "react";
import {useMountEffect} from "primereact/hooks";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import useUserNotification from "../hooks/useUserNotification";
import {Checkbox} from "primereact/checkbox";
import {userDataImportService} from "../service/exchange/excel/UserDataImportService";
import {FieldMetadata} from "../interfaces/FieldMetadata";
import {errorMessage} from "../helpers/axiosError";
import {UserDataImportSettings} from "../interfaces/UserDataImportSettings";

interface UserDataImportSettingsPanelProps {
    settings: UserDataImportSettings;
}

export default function UserDataImportSettingsPanel({settings}: UserDataImportSettingsPanelProps) {

    const {showError} = useUserNotification();

    const [busy, setBusy] = useState<boolean>(false);
    const [fieldsMetadata, setFieldsMetadata] = useState<FieldMetadata[]>([]);
    const [selected, setSelected] = useState<FieldMetadata[]>([]);


    const reload = () => {
        userDataImportService
            .fieldsMetadata()
            .then(response => {
                const fms: FieldMetadata[] = response.data;
                const selection: FieldMetadata[] = [];

                fms
                    .filter(fm => settings.items?.some(s => s.field === fm.field && s.enabled))
                    .forEach(fm => selection.push(fm));

                setFieldsMetadata(fms);
                setSelected(selection);

            })
            .catch(reason => {
                setFieldsMetadata([]);
                setSelected([]);
                showError({
                    summary: "Загрузка метаданных",
                    detail: errorMessage(reason)
                })
            })
            .finally(() => setBusy(false))
    }

    useMountEffect(() => reload());


    return (
        <div className="flex flex-column gap-4">
            <BlockUI blocked={busy}>
                <div className="flex flex-column gap-2">
                    <p className={"note"}>Эта совокупность настроек сохраняется в профиле текущего пользователя и
                        связана только с ним. Каждый пользователь системы может индивидуализировать настройки согласно
                        своим предпочтениям и характеру выполняемой работы.</p>
                    <div className="flex flex-row flex-nowrap align-items-stretch gap-2">

                        <Checkbox id="skipEmptyValue"
                                  checked={settings.skipEmptyValue === undefined ? true : settings.skipEmptyValue}
                                  onChange={(e) => {
                                      settings.skipEmptyValue = e.checked;
                                      // console.log(e.checked)
                                      // setSettings(prev => ({
                                      //     ...prev,
                                      //     skipEmptyValue: e.checked
                                      // }));
                                  }}/>

                        <div className="flex flex-column">
                            <label htmlFor="skipEmptyValue">Пропускать пустые ячейки</label>
                            <div className="note">Игнорировать пустые ячейки при сравнении записей базы данных и
                                импортируемого файла.
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="note">Только атрибуты, отмеченные в таблице ниже, будут учитываться при сравнении
                            базы данных и
                            импортируемого файла.</p>

                        <DataTable value={fieldsMetadata} dataKey={"field"}
                                   selectionMode={"checkbox"}
                                   selection={selected}
                                   onSelectionChange={(e) => setSelected(e.value)}
                                   size={"small"}>

                            <Column selectionMode="multiple"
                                    headerStyle={{width: '3rem'}}/>
                            <Column header={"Название атрибута"}
                                    field={"title"}
                                    dataType={"text"}/>
                            <Column header={"Системное имя"}
                                    field={"field"}
                                    dataType={"text"}/>

                        </DataTable>
                    </div>
                </div>
            </BlockUI>
        </div>
    );
};